/** Match Score Styles **/
.bg-red { background-color: rgba(255, 127, 80, 1); }
.bg-yellow { background-color: rgba(255, 215, 0, 1); }
.score-card {
    width: 100%;
}
.score-card .table {
    color: #000000;
    text-align: center;
}
.score-card .table thead>tr>th {
    font-size: 0.585rem;
}
.score-card .table thead>tr>th,
.score-card .table tbody>tr>td {
    border-color: #000000;
    border-width: 1px;
    border-left-style: solid;
    padding: 0px 1px;
    vertical-align: middle;
}
.score-card .table thead>tr>th:last-child,
.score-card .table tbody>tr>td:last-child {
    border-right-style: solid;
}
.score-card .table tbody>tr:last-child td {
    border-bottom-style: solid;
}
.score-card .table thead>tr>th.bsolid-left,
.score-card .table tbody>tr>td.bsolid-left {
    border-left-width: 2px;
}
.score-card .table thead>tr>th.bsolid-right,
.score-card .table tbody>tr>td.bsolid-right {
    border-right-width: 2px;
}
.score-card .table tr>td.box-size {
    width: 25px;
    font-weight: bold;
}
.score-card .table tr>td span { display: block; }
.score-card .table tr>td span.partner {
    border-top: 1px solid #000000 !important;
}
.score-card .table tr>td .player {
    padding: 1px 0px;
}
/** Player Box Styles **/
.box-player {
    border: 1px solid #fff;
    cursor: pointer;
}
.box-player:hover {
    border-color: #545b62;
}
.box-player .score {
    font-weight: bold;
    font-size: 1.5rem;
}
.box-player .score.service {
    padding: 0px 35px;
    margin: 0px 5px;
}
.box-player .score.service.ball-left {
    background-image: url("/src/ball.png");
    background-repeat: no-repeat;
    background-position: left;
}
.box-player .score.service.ball-right {
    background-image: url("/src/ball.png");
    background-repeat: no-repeat;
    background-position: right;
}
.box-player .player-name {
    background-color: rgba(52, 58, 64, 0.8);
    color: #fff;
}
.player-square .line-top:before,
.player-square .line-bottom:after {
    content: "";
    width: 75%;
    margin: 0 auto;
}
.player-square .line-top:before {    
    border-top: 1px solid #000;
}
.player-square .line-bottom:after {    
    border-bottom: 1px solid #000;
}
.player-square .line-left {
    border-left: 1px solid #000;
}
.player-square .line-right {    
    border-right: 1px solid #000;
}

/** Mobile view **/
@media (max-width: 767px) {
    .score-card .table tr>td {
        font-size: 0.55rem;
    }
    .score-card .table tr>td.box-size {
        width: 20px;
    }
}