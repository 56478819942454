/** Landing page styles **/
.landing-page .logo {
    width: 70px;
    margin: 5% auto 0 auto;
    height: 100%;
  }
  .landing-page .logo>img{
    animation: rotation 5s infinite linear;
    animation-timing-function: linear;
  }
  @keyframes rotation {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(360deg);
    }
  }
  .landing-page footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }