/** Dashboard Module Custom styles **/
table.table {
    border-collapse: separate !important;
    border-spacing: 0;
}
table.table tr>th, table.table tr>td{
    height: auto;
    font-size: 0.785rem;
    padding: 4px 7.5px;
}
table.table tr>td img {
    width: 30px;
    height: auto;
}
table.table tr>td span.thshow {
    display: none;
}
table.table tr>td span.text-inline {
    display: block;
    text-overflow: clip;
    overflow: hidden;
    width: inherit;
    white-space: nowrap;
}
/*table.table tr>td span.text-inline {
    max-width: 268px;
}*/
table.table tr>td.action {
    text-align: center;
    clear: both;
}
/** mobile view **/
@media (max-width: 767px) {
    table.mobile-view,
    table.mobile-view > * {
        display: block;
    }
    table.mobile-view > thead {
        display: none;
    }
    table.mobile-view > tbody > tr > td > span,
    table.mobile-view > tbody > tr > td > div {
        width: 100% !important;
        padding: 3px 5px;
        white-space: break-spaces;
        line-height: 15px;
    }
    table.mobile-view > tbody > tr > td {
        border-right: 0px solid !important;
        max-width: calc(100% - 20px) !important;
        margin-left: 10px!important;
        text-align: left;
        padding: 8px 0px!important;
    }
    table.mobile-view > tbody > tr {
        background: #fff;
        margin-bottom: 20px!important;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
        border: 1px solid rgba(0,0,0,0.2);
        border-radius: 6px;
        padding: 8px 0px!important;
    }
    table.mobile-view, 
    table.mobile-view > tbody > tr, 
    table.mobile-view > tbody > tr > td {
        display: block;
        width: 100% !important;
        margin: 0px;        
    }
    table.mobile-view > tbody > tr > td {
        border-left: 0;
    }
    table.mobile-view > tbody > tr > td:first-child {
        border: 0;
    }
    table.mobile-view > tbody > tr > td:last-child {
        border: 0;
    }
    table.mobile-view > tbody > tr > td.col-8-lt {
        max-width: calc(65.3% - 7px)!important;
        float: left;
        margin-right: -11px;
    }
    table.mobile-view > tbody > tr > td.col-6-lt {
        max-width: calc(50% - 10.5px)!important;
        float: left;
        margin-right: -11px;
    }
    table.mobile-view > tbody > tr > td.col-4-lt {
        max-width: calc(33.3% - 7px)!important;
        float: left;
        margin-right: -11px;
    }
    table.mobile-view tr td span.thshow {
        display: block;
        width: 100%;
        font-size: 12px;
        color: #1d46ef;
        font-weight: 500;
        margin-bottom: 4px;
    }
    table.mobile-view tr td.tdsno,
    table.mobile-view tr td.action span.thshow {
        display: none;
    }
}