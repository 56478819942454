/** Custom Style **/
body {
  padding-top: 4rem;
}
.w-15 { width: 15% !important; }
.bg-blue, .bg-orange, .bg-red { color: #fff; }
.bg-blue { background-color: #1c4595; }
.bg-orange { background-color: #d49d59; }
.bg-red { background-color: #ed1d25; }
.text-blue { color: #1c4595; }
.text-orange { color: #d49d59; }
.text-red { color: #ed1d25; }
span.error, small.error { color: #ed1d25; }
.loader {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  background: #ffffff;
  text-align: center;
  justify-content: center;
  align-items: center;
  opacity: 0.6;
}
.loader.show {
  display: flex;
  flex-direction: column;
}
.loader.hide {
  display: none;
}
.btn-mini {
  font-size: 0.675rem;
  border-radius: 0.1875rem;
  padding: 0.25rem 0.405rem;
}
.page-header { margin-bottom: 0.5rem !important; }
.mobile-app { width: 420px;}
/** mobile view **/
@media (max-width: 767px) {
  .loader.show {
    flex-direction: row;
  }
  .loader>div>span {
    display: block;
    width: 100%;
    text-align: center;
  }
  .mobile-app { width: 100%; }
}