/** Login page styles **/
.toasts-container {
    flex-direction: initial !important;
}
.login-box {
    margin-top: 100px;
    max-width: 600px;
    min-height: 320px;
    height: auto;
    border: 1px solid #9C9C9C;
    background-color: #EAEAEA;
    border-radius: 10px;
}
.login-box .form {
    padding: 20px;
}